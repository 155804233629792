import * as React from 'react'
import parse from "html-react-parser";
import {graphql, useStaticQuery} from "gatsby";
import "./globalAnnouncement.scss"

const GlobalAnnouncement = (props) => {
    const data = useStaticQuery(graphql`
        {
          craft {
            globalSets(handle: "announcement") {
              ... on Craft_announcement_GlobalSet {
                globalAnnouncementVisible
                globalAnnouncementText
              }
            }
          }
        }
  `)

    if(data.craft.globalSets[0].globalAnnouncementVisible === true) {
        return (
            <div className="announcment-bar">
                <div className="container">
                    {parse(data.craft.globalSets[0].globalAnnouncementText)}
                </div>
            </div>
        )
    } else {
        return null
    }
}

export default GlobalAnnouncement