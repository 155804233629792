import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Helmet } from "react-helmet"
import parse from "html-react-parser"

const Seo = ({ seomatic }) => {

    const data = useStaticQuery(graphql`
      {
        craft {
            seomatic {
                metaTitleContainer
                metaTagContainer
                metaLinkContainer
                metaScriptContainer
                metaJsonLdContainer
                metaSiteVarsContainer
            }
        }    
      }
    `)

    const seo = {
        metaTitleContainer: seomatic && seomatic.metaTitleContainer ? seomatic.metaTitleContainer : data.craft.seomatic.metaTitleContainer,
        metaTagContainer: seomatic && seomatic.metaTagContainer ? seomatic.metaTagContainer : data.craft.seomatic.metaTagContainer,
        metaLinkContainer: seomatic && seomatic.metaLinkContainer ? seomatic.metaLinkContainer : data.craft.seomatic.metaLinkContainer,
        metaScriptContainer: seomatic && seomatic.metaScriptContainer ? seomatic.metaScriptContainer : data.craft.seomatic.metaScriptContainer,
        metaJsonLdContainer: seomatic && seomatic.metaJsonLdContainer ? seomatic.metaJsonLdContainer : data.craft.seomatic.metaJsonLdContainer,
        metaSiteVarsContainer: seomatic && seomatic.metaSiteVarsContainer ? seomatic.metaSiteVarsContainer : data.craft.seomatic.metaSiteVarsContainer
    }

    return (
        <Helmet>
            {seo.metaTitleContainer && parse(seo.metaTitleContainer)}
            {seo.metaTagContainer && parse(seo.metaTagContainer)}
            {seo.metaLinkContainer && parse(seo.metaLinkContainer)}
            {seo.metaScriptContainer && parse(seo.metaScriptContainer)}
            {seo.metaJsonLdContainer && parse(seo.metaJsonLdContainer)}
            {seo.metaSiteVarsContainer && parse(seo.metaSiteVarsContainer)}
        </Helmet>
    )
}

export default Seo