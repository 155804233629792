import * as React from 'react'
import {useStaticQuery, graphql} from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons'
import cssImg from  "../../images/css-img.png"
import footerLogo from  "../../images/footer-logo.png"
import logo from  "../../images/logo.png"
import "./footer.scss"


const Footer = () => {
    const data = useStaticQuery(graphql`
        {
          craft {
            footerMenu: navigationNodes(navHandle: "corporateFooterNavigation", level: 1, status: null) {
                key: id
                title
                nodeUri
                enabled
                classes
                children {
                    key: id
                    title
                    nodeUri
                    classes
                }
            }
          }
        }
    `)

    const getUrl = (node) => {
        // Test if empty
        if (node.nodeUri) {
            // Test if absolute URL
            var r = new RegExp('^(?:[a-z]+:)?//', 'i');
            if (r.test(node.nodeUri)) {
                return node.nodeUri
            } else {
                return `/${node.nodeUri}`
            }
        } else {
            return '#'
        }
    }

    const getYear = () => {
        return new Date().getFullYear()
    }

    const navWalker = (nodes, child = false) => {
        if (!nodes || nodes.length === 0) {
            return null
        }

        if (child) {
            return nodes.map(node => (
                <li key={node.key}>
                    <a href={getUrl(node)} className={node.classes}>{node.title}</a>
                    <ul>{ navWalker(node.children, true) }</ul>
                </li>
            ))
        } else {
            return nodes.map(node => (
                <ul>
                    {node.url == null && node.enabled === true ? (
                        <h5 key={node.title}>{node.title}</h5>
                    ):(
                        null
                    )}
                    { navWalker(node.children, true) }
                </ul>
            ))
        }
    }

    return (
        <div className="footer">
            <div className="container">
                <div className="footer-mobile-logo-top"><a href="/"><img src={logo} alt="" /></a></div>
                <div className="footer-row">
                    <div className="footer-left">
                        <h4>MORE CHICKEN SOUP FOR THE SOUL</h4>
                        <div className="footer-menu">
                            {navWalker(data.craft.footerMenu)}
                        </div>
                    </div>

                    <div className="footer-right">
                        <div className="footer-right-inner">
                            <h4>FOLLOW US:</h4>
                            <div className="social-link">
                                <ul>
                                    <li key={'facebook'}><a href="https://www.facebook.com/ChickenSoupfortheSoul" target="_blank" rel="noreferrer" aria-label="Facebook"><FontAwesomeIcon icon={faFacebookF} /></a></li>
                                    <li key={'instagram'}><a href="https://instagram.com/chickensoupsoul" target="_blank" rel="noreferrer" aria-label="Instagram"><FontAwesomeIcon icon={faInstagram} /></a></li>
                                    <li key={'twitter'}><a href="https://twitter.com/chickensoupsoul" target="_blank" rel="noreferrer" aria-label="Twitter"><FontAwesomeIcon icon={faTwitter} /></a></li>
                                </ul>
                            </div>
                            <div className="css-img"><img src={cssImg} alt="Chicken Soup for the Soul Soupman reaching for heart" /></div>
                        </div>
                    </div>
                </div>

                <div className="copy-right">
                    <div className="copyright-txt">Copyright {getYear()}, Chicken Soup for the Soul, LLC. All Rights
                        Reserved.
                    </div>
                    <div className="footer-logo"><img src={footerLogo} alt="Chicken Soup for the Soul logo" /></div>
                </div>

            </div>
        </div>
    )
}

export default Footer