import * as React from 'react'
import {graphql, useStaticQuery} from "gatsby"
import Button from "../global/button"
import parse from "html-react-parser"

import './newsletterSignup.scss'

const NewsletterSignup = ({entryId, forceEnabled}) => {

    const data = useStaticQuery(graphql`
        {
          craft {
            globalSets(handle: "newsletterSignUpCta") {
              ... on Craft_newsletterSignUpCta_GlobalSet {
                newsletterSignUpCtaTitle
                newsletterSignUpCtaText
                newsletterSignUpCtaButtonLink {
                  linkUrl
                  linkText
                  openInNewTab
                }
                newsletterSignUpCtaEntries {
				    id
                }
              }
            }
          }
        }
  `)

    let hasEntryId = data.craft.globalSets[0].newsletterSignUpCtaEntries.some(node => node.id === entryId)
    let enabled = forceEnabled || false

    if (enabled || hasEntryId) {
        return (
            <div className="newsletter-signup">
                <div className="container">
                    <h2>{data.craft.globalSets[0].newsletterSignUpCtaTitle}</h2>
                    <p>{parse(data.craft.globalSets[0].newsletterSignUpCtaText)}</p>
                    <Button classes="btn-primary" to={data.craft.globalSets[0].newsletterSignUpCtaButtonLink[0].linkUrl} openInNewTab={data.craft.globalSets[0].newsletterSignUpCtaButtonLink[0].openInNewTab} text={data.craft.globalSets[0].newsletterSignUpCtaButtonLink[0].linkText} />
                </div>
            </div>
        )
    } else {
        return null
    }
}

export default NewsletterSignup