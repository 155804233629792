import * as React from 'react'

const Button = (props) => {
    const getTarget = (openInNewTab) => {
        if (openInNewTab) {
            return "_blank"
        } else {
            return "_self"
        }
    }

    const getClasses = (classes) => {
        return "btn " + classes
    }

    return (
        <a href={props.to} className={getClasses(props.classes)} target={getTarget(props.openInNewTab)} onClick={props.clickHandler}>{props.text}</a>
    )
}

export default Button